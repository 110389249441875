












import Vue from 'vue';
import { Component, Prop } from "vue-property-decorator";
import { CrewMemberModel, UserModel } from '../../../api/generated/api';
import { allowUserOnApp, blockAppAccess, inviteUserToApp } from '@/utility/inviteUserToApp';

@Component({})
export default class InviteToAppMenuItem extends Vue {
  @Prop() crewMember: CrewMemberModel | undefined;
  @Prop() user: UserModel | undefined;

  public async onInviteToApp() {
    try {
      this.$emit('loading', true);
      await inviteUserToApp(this.crewMember!);
    } finally {
      this.$emit('loading', false);
    }
  }

  public async onAllowAppAccess() {
    try {
      this.$emit('loading', true);
      const member = await allowUserOnApp(this.user!);

      if(this.crewMember) {
        const crew: CrewMemberModel = {
          ...this.crewMember,
          linkedUser: member
        };

        this.$emit('update-grid', crew);
      }

      if(this.user && !this.crewMember) {
        this.$emit('update-grid', member);
      }
    } finally {
      this.$emit('loading', false);
    }
  }

  public async onBlockAppAccess() {
    try {
      this.$emit('loading', true);
      const member = await blockAppAccess(this.user!);

      if(this.crewMember) {
        const crew: CrewMemberModel = {
          ...this.crewMember,
          linkedUser: member
        };

        this.$emit('update-grid', crew);
      }

      if(this.user && !this.crewMember) {
        this.$emit('update-grid', member);
      }
    } finally {
      this.$emit('loading', false);
    }
  }
}
