




import Vue from "vue";
import MenuButton from "./MenuButton.vue";

export default Vue.extend({
  components: {
    MenuButton
  }
});
