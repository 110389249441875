import { CrewMemberModel, UserModel } from "@/api/generated";
import Api from "@/api";
import SnackbarModule from "@/store/snackbarModule";
import { getModule } from "vuex-module-decorators";
import { appApiClient } from "@/services/apiService";
const snackbarModule = getModule(SnackbarModule);

export async function inviteUserToApp(item: CrewMemberModel) {
  if (item.crewMemberId != null) {
    if(!item.emailAddress) {
      snackbarModule.setSnackbarMessage("Please add an email address to this crew member first.");
      return;
    }

    await Api.UserService.apiUserPost({
      firstname: item.firstname,
      lastname: item.lastname,
      emailAddress: item.emailAddress,
      isAccessToApp: true,
      role: "CRW",
      crewMemberId: item.crewMemberId
    });

    snackbarModule.setSnackbarMessage("User invited to app.");
  }
}

export async function allowUserOnApp(user: UserModel): Promise<UserModel> {
  if (user != null) {
    user.isAccessToApp = true;

    try {
      await Api.UserService.apiUserUserIdPut(user.userId!, user);
      snackbarModule.setSnackbarMessage("User can log onto app.");
    } catch {
      user.isAccessToApp = false;
      snackbarModule.setSnackbarMessage("Could not allow user.");
    }
  }

  return user;
}

export async function blockAppAccess(user: UserModel): Promise<UserModel> {
  if (user != null) {
    user.isAccessToApp = false;

    try {
      await Api.UserService.apiUserUserIdPut(user.userId!, user);
      snackbarModule.setSnackbarMessage("User blocked from app, please allow a few minutes to propagate.");
    } catch {
      user.isAccessToApp = true;
      snackbarModule.setSnackbarMessage("Could not block user.");
    }

    // // TODO: Delete refresh token.
    // // TODO: Possibly remove access to the chat rooms in ChatApp.

  }

  return user;
}