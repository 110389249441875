











































































import { Vue, Component } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/snackbarModule";
import Api from "@/api";
import { CrewMemberModel } from "@/api/generated";
import nameof from "@/utility/nameof";
import UserModule from "@/store/userModule";
import FileDownloadUtility from "@/utility/fileDownloadUtility";
import CrewMemberMenuItems from "@/components/common/menu-items/Groups/CrewMemberMenuItems.vue";
import MenuWrapper from "@/components/common/menu-items/MenuWrapper.vue";

const snackbarModule = getModule(SnackbarModule);
const userModule = getModule(UserModule);

@Component({
  components: {
    CrewMemberMenuItems,
    MenuWrapper
  }
})
export default class CrewMemberTable extends Vue {
  public loading = false;
  private crewMembers: Array<CrewMemberModel> = [];
  private search = "";
  private headers: Array<DataTableHeader> = [
    { text: "", value: "actions", sortable: false },
    { text: "Name", value: nameof<CrewMemberModel>("fullname") },
    { text: "Known As", value: nameof<CrewMemberModel>("knownAs") },
    { text: "Rating", value: nameof<CrewMemberModel>("rating") },
    {
      text: "Nationality",
      value: nameof<CrewMemberModel>("countryNationality")
    },
    { text: "Age", value: nameof<CrewMemberModel>("dateOfBirth") },
    {
      text: "Current Vessel",
      value: nameof<CrewMemberModel>("currentVessel")
    },
    {
      text: "Current Vessel RSS",
      value: nameof<CrewMemberModel>("currentVesselRss")
    },
    {
      text: "Agency",
      value: nameof<CrewMemberModel>("agencyName")
    },
    {
      text: "Passport Expiry",
      value: nameof<CrewMemberModel>("passportExpiry")
    },
    {
      text: "Date of Termination",
      value: nameof<CrewMemberModel>("employmentEndDate")
    },
    {
      text: "Last Note",
      value: nameof<CrewMemberModel>("lastNote")
    },
    { text: "App Access", value: nameof<CrewMemberModel>("linkedUser") },
    { text: "Active", value: nameof<CrewMemberModel>("active") },
    { text: "JobRole", value: nameof<CrewMemberModel>("crewJobRoles") },
    { text: "", value: nameof<CrewMemberModel>("doNotUse") }
  ];
  private agencyAdminHeaders: Array<DataTableHeader> = [
    { text: "", value: "actions", sortable: false },
    { text: "Name", value: nameof<CrewMemberModel>("fullname") },
    { text: "Known As", value: nameof<CrewMemberModel>("knownAs") },
    { text: "Rating", value: nameof<CrewMemberModel>("rating") },
    {
      text: "Nationality",
      value: nameof<CrewMemberModel>("countryNationality")
    },
    { text: "Age", value: nameof<CrewMemberModel>("dateOfBirth") },
    {
      text: "Current Vessel",
      value: nameof<CrewMemberModel>("currentVessel")
    },
    {
      text: "Current Vessel RSS",
      value: nameof<CrewMemberModel>("currentVesselRss")
    },
    {
      text: "Passport Expiry",
      value: nameof<CrewMemberModel>("passportExpiry")
    },
    {
      text: "Date of Termination",
      value: nameof<CrewMemberModel>("employmentEndDate")
    },
    {
      text: "Last Note",
      value: nameof<CrewMemberModel>("lastNote")
    },
    { text: "Active", value: nameof<CrewMemberModel>("active") },
    { text: "JobRole", value: nameof<CrewMemberModel>("crewJobRoles") },
    { text: "", value: nameof<CrewMemberModel>("doNotUse") }
  ];

  private get agencyAdminRole() {
    return userModule.role == "AGM";
  }

  private get tableHeaders() {
    if (userModule.role == "AGM") {
      return this.agencyAdminHeaders;
    } else {
      return this.headers;
    }
  }

  private async created() {
    await this.getCrewMembers();
  }

  private async getCrewMembers() {
    try {
      this.loading = true;
      const response = await Api.CrewMemberService.apiCrewmemberGet();
      this.crewMembers = response.data.filter(
        x => !x.active && !x.skilledWorker
      );
    } catch {
      snackbarModule.setSnackbarMessage("Failed to retrieve crew members");
    } finally {
      this.loading = false;
    }
  }

  public updateCrewMemberLocally(item: CrewMemberModel) {
    const index = this.crewMembers.findIndex(member => member.crewMemberId === item.crewMemberId);

    if(index) {
      this.crewMembers[index] = item;
    } else {
      this.getCrewMembers();
    }
  }

  private async onDownloadTransferRequestForm(item: CrewMemberModel) {
    try {
      this.loading = true;
      if (item.crewMemberId != null) {
        const response = await Api.ReportService.apiReportCrewmembertransferrequestformIdGet(
          item.crewMemberId
        );
        FileDownloadUtility.DownloadFile(
          response.data,
          `${item.fullname} Transfer Request Form.docx`
        );
      }
    } finally {
      this.loading = false;
    }
  }

  private async onDownloadGdprForm(item: CrewMemberModel) {
    try {
      this.loading = true;
      if (item.crewMemberId != null) {
        const response = await Api.ReportService.apiReportGdprCrewIdGet(
          item.crewMemberId
        );
        FileDownloadUtility.DownloadFile(
          response.data,
          `${item.fullname} GDPR.docx`
        );
      }
    } finally {
      this.loading = false;
    }
  }

  private async onDownloadGdprAkanForm(item: CrewMemberModel) {
    try {
      this.loading = true;
      if (item.crewMemberId != null) {
        const response = await Api.ReportService.apiReportGdprAkanCrewIdGet(
          item.crewMemberId
        );
        FileDownloadUtility.DownloadFile(
          response.data,
          `${item.fullname} GDPR Akan.docx`
        );
      }
    } finally {
      this.loading = false;
    }
  }

  private async onDownloadGdprHindiForm(item: CrewMemberModel) {
    try {
      this.loading = true;
      if (item.crewMemberId != null) {
        const response = await Api.ReportService.apiReportGdprHindiCrewIdGet(
          item.crewMemberId
        );
        FileDownloadUtility.DownloadFile(
          response.data,
          `${item.fullname} GDPR Hindi.docx`
        );
      }
    } finally {
      this.loading = false;
    }
  }

  private async onDownloadGdprSinhalaForm(item: CrewMemberModel) {
    try {
      this.loading = true;
      if (item.crewMemberId != null) {
        const response = await Api.ReportService.apiReportGdprSinhalaCrewIdGet(
          item.crewMemberId
        );
        FileDownloadUtility.DownloadFile(
          response.data,
          `${item.fullname} GDPR Sinhala.docx`
        );
      }
    } finally {
      this.loading = false;
    }
  }

  private async onDownloadGdprTagalogForm(item: CrewMemberModel) {
    try {
      this.loading = true;
      if (item.crewMemberId != null) {
        const response = await Api.ReportService.apiReportGdprTagalogCrewIdGet(
          item.crewMemberId
        );
        FileDownloadUtility.DownloadFile(
          response.data,
          `${item.fullname} GDPR Tagalog.docx`
        );
      }
    } finally {
      this.loading = false;
    }
  }

  private async onDownloadLetterOfEmployment(item: CrewMemberModel) {
    try {
      this.loading = true;
      if (item.crewMemberId != null) {
        const response = await Api.ReportService.apiReportCrewmemberletterofemploymentIdGet(
          item.crewMemberId
        );
        FileDownloadUtility.DownloadFile(
          response.data,
          `${item.fullname} Letter of Employment.docx`
        );
      }
    } catch {
      snackbarModule.setSnackbarMessage(
        "Crew member has no previous contracts"
      );
    } finally {
      this.loading = false;
    }
  }

  private onCreateCrewMember() {
    this.$router.push({ name: "Crew Member Create" });
  }

  public async onDeleteCrewMember(crewMember: CrewMemberModel) {
    if (!crewMember.crewMemberId) {
      return;
    }

    try {
      await Api.CrewMemberService.apiCrewmemberCrewMemberIdDelete(
        crewMember.crewMemberId
      );
      snackbarModule.setSnackbarMessage(`Crew member successfully deleted`);
    } catch {
      snackbarModule.setSnackbarMessage(`Failed to delete crew member`);
    }

    await this.getCrewMembers();
  }

  public onUpdateCrewMember(crewMember: CrewMemberModel) {
    if (!crewMember.crewMemberId) {
      return;
    }

    if (userModule.role == "AGM") {
      this.$router.push({
        name: "Crew Member Edit Agency",
        params: { crewMemberId: crewMember.crewMemberId }
      });
    } else {
      this.$router.push({
        name: "Crew Member Edit",
        params: { crewMemberId: crewMember.crewMemberId }
      });
    }
  }

  private async updateDoNotUse(item: CrewMemberModel) {
    try {
      if (item.crewMemberId) {
        await Api.CrewMemberService.apiCrewmemberCrewMemberIdPut(
          item.crewMemberId,
          item
        );
      }
    } catch {
      snackbarModule.setSnackbarMessage("Failed to update user to Do not use");
    } finally {
      await this.getCrewMembers();
    }
  }
}
