




import Vue from "vue";
import MenuButton from "./MenuButton.vue";

export default Vue.extend({
  props: ['deleteConfirmText'],
  components: {
    MenuButton
  },
  methods: {
    async onDelete() {
      const res = await this.$confirm(this.deleteConfirmText ? this.deleteConfirmText : "Are you sure?", { title: 'Warning' })

      if (!res) return;

      this.$emit('delete');
    }
  }
});
