




import Vue from "vue";
import MenuButton from "../MenuButton.vue";
import { CrewMemberModel } from '../../../../api/generated/api';
import Api from "@/api";
import FileDownloadUtility from "@/utility/fileDownloadUtility";
import { AxiosResponse } from 'axios';

export default Vue.extend({
  props: ['item'],
  components: {
    MenuButton
  },
  methods: {
    async onDownload() {
      try {
        const item = this.item as CrewMemberModel;

        this.$emit('loading', true);

        if (item.crewMemberId != null) {
          const response = await Api.ReportService.apiReportCrewmembertransferrequestformIdGet(
            item.crewMemberId
          );

          FileDownloadUtility.DownloadFile(
            response.data,
            `${item?.fullname} Transfer Request Form.docx`
          );
        }
      } finally {
        this.$emit('loading', false);
      }
    }
  }
});
